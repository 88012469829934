import React from "react";
import BlockInfo from "./Info";

const App = () => {
  return (
    <div>
      <h1>Metablock Deployment Starter</h1>
      <BlockInfo />
    </div>
  );
};

export default App;
